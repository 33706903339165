/* eslint-disable */

'use strict';

/*
 * SlickLightbox documentation #

Documentation generated by [CoffeeDoc](http://github.com/omarkhan/coffeedoc)
 */
(function ($) {
    var SlickLightbox,
        defaults;
    SlickLightbox = (function () {
        /*
    		The one and only class used.
     */
        function SlickLightbox(element, options) {
            var that;
            this.options = options;

            /* Binds the plugin. */
            this.element = $(element);
            this.didInit = false;
            that = this;
            this.element.on('click.slickLightbox', this.options.itemSelector, function (e) {
                e.preventDefault();
                return that.init(that.element.find(that.options.itemSelector).index($(this)));
            });
        }

        SlickLightbox.prototype.init = function (index) {
            /* Creates the lightbox, opens it, binds events and calls `slick`. Accepts `index` of the element, that triggered it (so that we know, on which slide to start slick). */
            this.didInit = true;
            this.detectIE();
            this.createModal(index);
            this.bindEvents();
            this.initSlick();
            return this.open();
        };

        SlickLightbox.prototype.createModalItems = function (index) {
            /* Creates individual slides to be used with slick. If `options.images` array is specified, it uses it's contents, otherwise loops through elements' `options.itemSelector`. */
            var a,
                createItem,
                links;
            if (this.options.images) {
                links = $.map(this.options.images, function (img) {
                    return '<div class="slick-lightbox-slick-item"><div class="slick-lightbox-slick-item-inner"><img class="slick-lightbox-slick-img" src="' + img + '" /></div></div>';
                });
            } else {
                createItem = (function (_this) {
                    return function (el) {
                        var caption,
                            src;
                        caption = _this.getElementCaption(el);
                        src = _this.getElementSrc(el);
                        if (_this.detectImage(src)) {
                            return '<div class="slick-lightbox-slick-item"><div class="slick-lightbox-slick-item-inner"><img class="slick-lightbox-slick-img" src="' + src + '" />' + caption + '</div></div>';
                        }
                        return '<div class="slick-lightbox-slick-item"><div class="slick-lightbox-slick-item-inner iframe"><div class="slick-lightbox-slick-iframe-wrap"><iframe class="slick-lightbox-slick-iframe" src="' + src + '" frameborder="0" allowfullscreen></iframe>' + caption + '</div></div></div>';
                    };
                }(this));
                a = this.element.find(this.options.itemSelector);
                if (index === 0 || index === -1) {
                    links = $.map(a, createItem);
                } else {
                    links = $.map(a.slice(index), createItem);
                    $.each(a.slice(0, index), function (i, el) {
                        return links.push(createItem(el));
                    });
                }
            }
            return links;
        };

        SlickLightbox.prototype.createModal = function (index) {
            /* Creates a `slick`-friendly modal. Rearranges the items so that the `index`-th item is placed first. */
            var html,
                links;
            links = this.createModalItems(index);
            html = '<div class="slick-lightbox slick-hide-init' + (this.isIE ? ' slick-lightbox-ie' : '') + '" style="background: ' + this.options.background + ';">\n	<div class="slick-lightbox-inner">\n		<div class="slick-lightbox-slick slick-caption-' + this.options.captionPosition + '">' + (links.join('')) + '</div>\n		<button type="button" class="slick-lightbox-close"></button>\n	<div>\n<div>';
            this.modalElement = $(html);
            return $('body').append(this.modalElement);
        };

        SlickLightbox.prototype.initSlick = function (index) {
            /* Runs slick by default, using `options.slick` if provided. If `options.slick` is a function, it gets fired instead of us initializing slick. */
            if (this.options.slick != null) {
                if (typeof this.options.slick === 'function') {
                    /* Function expected to return slick instance. */
                    this.slick = this.options.slick(this.modalElement);
                } else {
                    this.slick = this.modalElement.find('.slick-lightbox-slick').slick(this.options.slick);
                }
            } else {
                this.slick = this.modalElement.find('.slick-lightbox-slick').slick();
            }
            return this.modalElement.trigger('init.slickLightbox');
        };

        SlickLightbox.prototype.open = function () {
            /* Opens the lightbox. */
            this.element.trigger('show.slickLightbox');
            setTimeout(((function (_this) {
                return function () {
                    return _this.element.trigger('shown.slickLightbox');
                };
            })(this)), this.getTransitionDuration());
            return this.modalElement.removeClass('slick-hide-init');
        };

        SlickLightbox.prototype.close = function () {
            /* Closes the lightbox and destroys it, maintaining the original element bindings. */
            this.element.trigger('hide.slickLightbox');
            setTimeout(((function (_this) {
                return function () {
                    return _this.element.trigger('hidden.slickLightbox');
                };
            })(this)), this.getTransitionDuration());
            this.modalElement.addClass('slick-hide');
            return this.destroy();
        };

        SlickLightbox.prototype.bindEvents = function () {
            /* Binds global events. */
            var resizeSlides;
            resizeSlides = (function (_this) {
                return function () {
                    var h;
                    h = _this.modalElement.find('.slick-lightbox-inner').height();
                    _this.modalElement.find('.slick-lightbox-slick-item').height(h);
                    return _this.modalElement.find('.slick-lightbox-slick-img').css('max-height', Math.round(0.9 * h));
                };
            }(this));
            $(window).on('orientationchange.slickLightbox resize.slickLightbox', resizeSlides);
            this.modalElement.on('init.slickLightbox', resizeSlides);
            this.modalElement.on('destroy.slickLightbox', (function (_this) {
                return function () {
                    return _this.destroy();
                };
            }(this)));
            this.element.on('destroy.slickLightbox', (function (_this) {
                return function () {
                    return _this.destroy(true);
                };
            }(this)));
            this.modalElement.on('click.slickLightbox touchstart.slickLightbox', '.slick-lightbox-close', (function (_this) {
                return function (e) {
                    e.preventDefault();
                    return _this.close();
                };
            }(this)));
            if (this.options.closeOnEscape || this.options.navigateByKeyboard) {
                $(document).on('keydown.slickLightbox', (function (_this) {
                    return function (e) {
                        var code;
                        code = e.keyCode ? e.keyCode : e.which;
                        if (_this.options.navigateByKeyboard) {
                            if (code === 37) {
                                _this.slideSlick('left');
                            } else if (code === 39) {
                                _this.slideSlick('right');
                            }
                        }
                        if (_this.options.closeOnEscape) {
                            if (code === 27) {
                                return _this.close();
                            }
                        }
                    };
                }(this)));
            }
            if (this.options.closeOnBackdropClick) {
                this.modalElement.on('click.slickLightbox touchstart.slickLightbox', '.slick-lightbox-slick-img', (function (_this) {
                    return function (e) {
                        return e.stopPropagation();
                    };
                }(this)));
                this.modalElement.on('click.slickLightbox touchstart.slickLightbox', '.slick-lightbox-slick-iframe-wrap', (function (_this) {
                    return function (e) {
                        return e.stopPropagation();
                    };
                }(this)));
                return this.modalElement.on('click.slickLightbox touchstart.slickLightbox', '.slick-lightbox-slick-item', (function (_this) {
                    return function (e) {
                        e.preventDefault();
                        return _this.close();
                    };
                }(this)));
            }
        };

        SlickLightbox.prototype.slideSlick = function (direction) {
            /* Moves the slick prev or next. */
            if (direction === 'left') {
                return this.slick.slick('slickPrev');
            }
            return this.slick.slick('slickNext');
        };

        SlickLightbox.prototype.detectIE = function () {
            /* Detects usage of IE8 and lower. */
            var ieversion;
            this.isIE = false;
            if (/MSIE (\d+\.\d+);/.test(navigator.userAgent)) {
                ieversion = new Number(RegExp.$1);
                if (ieversion < 9) {
                    return this.isIE = true;
                }
            }
        };

        SlickLightbox.prototype.getElementCaption = function (el) {
            /* Returns caption for each slide based on the type of `options.caption`. */
            var c;
            if (!this.options.caption) {
                return '';
            }
            c = (function () {
                switch (typeof this.options.caption) {
                case 'function':
                    return this.options.caption(el);
                case 'string':
                    return $(el).data(this.options.caption);
                }
            }).call(this);
            return '<span class="slick-lightbox-slick-caption">' + c + '</span>';
        };

        SlickLightbox.prototype.getElementSrc = function (el) {
            /* Returns src for each slide image based on the type of `options.src`. */
            switch (typeof this.options.src) {
            case 'function':
                return this.options.src(el);
            case 'string':
                return $(el).attr(this.options.src);
            default:
                return el.href;
            }
        };

        SlickLightbox.prototype.detectImage = function (url) {
            /* Returns true if finds image file extension, optionally allow querystrings through if they exist */
            return url.match(/\.(jpeg|jpg|gif|png)(\?.*)?$/) !== null;
        };

        SlickLightbox.prototype.unbindEvents = function () {
            /* Unbinds global events. */
            $(window).off('.slickLightbox');
            $(document).off('.slickLightbox');
            return this.modalElement.off('.slickLightbox');
        };

        SlickLightbox.prototype.destroy = function (unbindAnchors) {
            if (unbindAnchors == null) {
                unbindAnchors = false;
            }

            /* Destroys the lightbox and unbinds global events. If `true` is passed as an argument, unbinds the original element as well. */
            if (this.didInit) {
                this.unbindEvents();
                setTimeout(((function (_this) {
                    return function () {
                        return _this.modalElement.remove();
                    };
                })(this)), this.options.destroyTimeout);
            }
            if (unbindAnchors) {
                this.element.off('.slickLightbox');
                return this.element.off('.slickLightbox', this.options.itemSelector);
            }
        };

        SlickLightbox.prototype.destroyPrevious = function () {
            /* Destroys lightboxes currently in DOM. */
            return $('body').children('.slick-lightbox').trigger('destroy.slickLightbox');
        };

        SlickLightbox.prototype.getTransitionDuration = function () {
            /* Detects the transition duration to know when to remove stuff from DOM etc. */
            var duration;
            if (this.transitionDuration) {
                return this.transitionDuration;
            }
            duration = this.modalElement.css('transition-duration');
            if (typeof duration === 'undefined') {
                return this.transitionDuration = 500;
            }
            return this.transitionDuration = duration.indexOf('ms') > -1 ? parseFloat(duration) : parseFloat(duration) * 1000;
        };

        return SlickLightbox;
    }());
    defaults = {
        background           : 'rgba(0,0,0,.8)',
        closeOnEscape        : true,
        closeOnBackdropClick : true,
        destroyTimeout       : 500,
        itemSelector         : 'a',
        navigateByKeyboard   : true,
        src                  : false,
        caption              : false,
        captionPosition      : 'dynamic',
        images               : false,
        slick                : {}
    };
    $.fn.slickLightbox = function (options) {
        /* Fires the plugin. */
        options = $.extend({}, defaults, options);
        new SlickLightbox(this, options);
        return this;
    };
    return $.fn.unslickLightbox = function () {
        /* Removes everything. */
        return $(this).trigger('destroy.slickLightbox');
    };
}(jQuery));
