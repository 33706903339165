'use strict';

module.exports = function () {
    var previousCountryClass = '.geolocationModalPreviousCountry';
    var selectedCountryClass = '.geolocationModalSelectedCountry';
    var geolocationModalClass = '.geolocationModal';
    var currentLocationCode = $(geolocationModalClass).data('currentlocationcode');
    var selectedLocationCode = $(geolocationModalClass).data('selectedlocationcode');
    var localeID = $(geolocationModalClass).data('localeid');
    var sessionName = 'selectedCountry';

    /* Add - PIK-202006-79 - 20201020 - Add a sessionStorage Variable with the preferred country by user */
    function setSessionSelectedCountry(cssClass) {
        sessionStorage.setItem(sessionName, $(cssClass).data('selectedcountry'));
    }

    /* Add - PIK-202006-113 - 20201209 - Redirect to a external Url if user is allocated in CA,CN,JP,MX */
    function redirectToExternalLocaleSiteInGeolocationModal(countryCode) {
        var urlMX = $('.geolocationModalSelectedCountry').data('urlmexico');
        var urlCA = $('.geolocationModalSelectedCountry').data('urlcanada');
        var urlCN = $('.geolocationModalSelectedCountry').data('urlchina');
        var urlJP = $('.geolocationModalSelectedCountry').data('urljapan');
        if (countryCode) {
            switch (countryCode) {
            case 'MX':
                window.location.href = urlMX;
                break;
            case 'CA':
                window.location.href = urlCA;
                break;
            case 'CN':
                window.location.href = urlCN;
                break;
            case 'JP':
                window.location.href = urlJP;
                break;
            default:
            }
        }
    }

    /* Add - PIK-202006-79 - 20201020 - If the user has selected previously a preferred country or if actualy
    the country site is the same of the geolocation don't display the geolocation modal */
    if (sessionStorage.getItem(sessionName)
        && (sessionStorage.getItem(sessionName) === currentLocationCode
            || sessionStorage.getItem(sessionName) === selectedLocationCode)
    ) {
        $(geolocationModalClass).css('display', 'none');
    } else {
        $(geolocationModalClass).css('display', 'block');

        /* Add - PIK-202006-79 - 20201020 - Close the modal */
        $('body').on('click', '.geolocationModalCloseButton', function (e) {
            e.preventDefault();
            $(geolocationModalClass).css('display', 'none');
        });

        /* Add - PIK-202006-79 - 20201020 - Set the selected country and saves in sessionStorage, close the modal */
        $('body').on('click', selectedCountryClass, function (e) {
            e.preventDefault();
            setSessionSelectedCountry(selectedCountryClass);
            $(geolocationModalClass).css('display', 'none');
        });

        /* Add - PIK-202006-79 - 20201020 - Redirect to geolocation place */
        $('body').on('click', previousCountryClass, function (e) {
            e.preventDefault();

            /* Add - PIK-202006-113 - 20201214 - Redirect to a external Url if user is allocated in CA,CN,JP,MX */
            if (currentLocationCode === 'MX' || currentLocationCode === 'CA' || currentLocationCode === 'CN' || currentLocationCode === 'JP') {
                redirectToExternalLocaleSiteInGeolocationModal(currentLocationCode);
            } else {
                var action = $('.page').data('action');
                var localeCode = localeID;
                var localeCurrencyCode = $('.geolocationModalPreviousCountry').data('currencycode');
                var queryString = $('.page').data('querystring');
                var url = $('.geolocationModalPreviousCountry').data('url');

                $.ajax({
                    url      : url,
                    type     : 'get',
                    dataType : 'json',
                    data     : {
                        code         : localeCode,
                        queryString  : queryString,
                        CurrencyCode : localeCurrencyCode,
                        action       : action
                    },
                    success: function (response) {
                        if (response && response.redirectUrl) {
                            window.location.href = response.redirectUrl;
                        }
                    },
                    error: function () {
                        console.log('error... action', action);
                    }
                });
            }
            $(geolocationModalClass).css('display', 'none');
        });
    }
};
