'use strict';

var base = require('./base');
var handleADA = require('../common/handleADA');

var mediaBreakLimit = 992;
var mainGallery =
{
    lazyLoad       : 'ondemand',
    dots           : true,
    arrows         : false,
    infinite       : false,
    vertical       : false,
    slidesToShow   : 1,
    slidesToScroll : 1
};

var optionsType =
{
    dots           : false,
    arrows         : true,
    infinite       : true,
    vertical       : false,
    slidesToShow   : 4,
    slidesToScroll : 1,
    responsive     : [{
        breakpoint : 1200,
        settings   : {
            slidesToShow   : 3,
            slidesToScroll : 1,
            arrows         : true
        }
    },
    {
        breakpoint : 991,
        settings   : {
            slidesToShow   : 3,
            slidesToScroll : 1,
            arrows         : true
        }
    },
    {
        breakpoint : 768,
        settings   : {
            slidesToShow   : 2,
            slidesToScroll : 1,
            arrows         : true
        }
    },
    {
        breakpoint : 500,
        settings   : {
            slidesToShow   : 2,
            slidesToScroll : 1,
            arrows         : true
        }
    }]
};

/** Fix carousel trigger keyboard */
$(document).bind('keyup', function (e) {
    if (e.which == 39) {
        $('.carousel').carousel('next');
    } else if (e.which == 37) {
        $('.carousel').carousel('prev');
    }
});

/**
 * Enable/disable UI elements
 * @param {boolean} enableOrDisable - true or false
 */
function updateAddToCartEnableDisableOtherElements(enableOrDisable) {
    $('button.add-to-cart-global').attr('disabled', enableOrDisable);
}

function showLightbox() {
    $('#galleryBox').slickLightbox({
        navigateByKeyboard: true,
        background: 'rgba(255,255,255,1)',
        lazy: true
    }).on({
        'show.slickLightbox': function () {
            $('meta[name="viewport"]').attr('content', 'width=device-width, initial-scale=1');
        },
        'hide.slickLightbox': function () {
            $('meta[name="viewport"]').attr('content', 'width=device-width, initial-scale=1, maximum-scale=1');
            $('#galleryBox').unslickLightbox();
        }
    });
}

/** Inicializacion del slick slider en la galeria principal */
function showMainGalleryMobile() {
    var deviceScreenWidth = $(window).width();
    if (deviceScreenWidth < mediaBreakLimit) {
        if (!$('#galleryBox').hasClass('slick-initialized')) {
            $('#galleryBox').slick(mainGallery);
            $('.slick-lightbox').remove();
            $('.slick-current.slick-active').on('click', function () {
                showLightbox();
            });
        }
    } else if ($('#galleryBox').hasClass('slick-initialized')) {
        $('#galleryBox').slick('unslick');
    }
}
/** cambia la posicion del título entre dispositivos */
function changeProductName() {
    var deviceScreenWidth = $(window).width();
    if (deviceScreenWidth < mediaBreakLimit) {
        $('#mediaContainer').parent().prepend($('#nameProductContainer'));
    } else {
        $('#infoProductContainer').prepend($('#nameProductContainer'));
    }
}

/** Cambia la posicion de los bloques de informacion */
function changeInfoPositions() {
    var deviceScreenWidth = $(window).width();
    if (deviceScreenWidth < mediaBreakLimit) {
        $('#recommendationsContainer').parent().prepend($('#descriptionContainer'));
        $('#recommendationsContainer').parent().prepend($('#sustainabilityContainer'));
        $('#recommendationsContainer').parent().prepend($('#shippingReturnsContainer'));
        $('#recommendationsContainer').parent().prepend($('#descriptionContainer'));
        $('#recommendationsContainer').parent().prepend($('#moreInfoContainer'));
    } else {
        $('#infoProductContainer').prepend($('#descriptionContainer'));
        $('#infoProductContainer').prepend($('#sustainabilityContainer'));
        $('#infoProductContainer').prepend($('#shippingReturnsContainer'));
        $('#infoProductContainer').prepend($('#descriptionContainer'));
        $('#infoProductContainer').prepend($('#moreInfoContainer'));
    }
}

function showMobileView() {
    showMainGalleryMobile();
    changeProductName();
    changeInfoPositions();
}

function ekomiName() {
    setTimeout(function () {
        var names = document.querySelectorAll('.reviews-stack .review_date');
        if (names.length > 0) {
            for (var nameIndex = 0; nameIndex < names.length; nameIndex++) {
                var name = names[nameIndex].firstChild.nodeValue.split(' ')[0];
                names[nameIndex].firstChild.nodeValue = name;
            }
        }
    }, 500);
}

module.exports = {
    methods: {
        updateAddToCartEnableDisableOtherElements: updateAddToCartEnableDisableOtherElements
    },
    availability: base.availability,
    addToCart: base.addToCart,
    showMainGalleryMobile: function () {
        $(document).ready(function () {
            showMobileView();
        });
        $(window).on('resize', function () {
            showMobileView();
        });
    },
    updateAttributesAndDetails: function () {
        $('body').on('product:statusUpdate', function (e, data) {
            var $productContainer = $('.product-detail[data-pid="' + data.id + '"]');

            $productContainer.find('.description-and-detail .product-attributes')
                .empty()
                .html(data.attributesHtml);

            if (data.shortDescription) {
                $productContainer.find('.product-short-description')
                    .removeClass('hidden-xl-down');
                $productContainer.find('.product-short-description').empty().html('<p>' + data.shortDescription + '</p>');
            } else {
                $productContainer.find('.product-short-description')
                    .addClass('hidden-xl-down');
            }

            if (data.longDescription) {
                $productContainer.find('.description-and-detail .details')
                    .removeClass('hidden-xl-down');
                $productContainer.find('.description-and-detail .details .content')
                    .empty()
                    .html(data.longDescription);
            } else {
                $productContainer.find('.description-and-detail .details')
                    .addClass('hidden-xl-down');
            }
        });
    },
    showSpinner: function () {
        $('body').on('product:beforeAddToCart product:beforeAttributeSelect', function () {
            $.spinner().start();
        });
    },
    updateAttribute: function () {
        $('body').on('product:afterAttributeSelect', function (e, response) {
            if ($('.product-detail>.bundle-items').length) {
                response.container.data('pid', response.data.product.id);
                response.container.find('.product-id').text(response.data.product.id);
            } else if ($('.product-set-detail').eq(0)) {
                response.container.data('pid', response.data.product.id);
                response.container.find('.product-id').text(response.data.product.id);
            } else {
                $('.product-id').text(response.data.product.id);
                $('.product-detail:not(".bundle-item")').data('pid', response.data.product.id);
            }
        });
    },
    updateAddToCart: function () {
        $('body').on('product:updateAddToCart', function (e, response) {
            // update local add to cart (for sets)
            $('button.add-to-cart', response.$productContainer).attr('disabled',
                (!response.product.availableCountry || !response.product.readyToOrder || !response.product.available || !response.product.availablePrice));

            var enable = $('.product-availability').toArray().every(function (item) {
                return $(item).data('availableCountry') && $(item).data('available') && $(item).data('ready-to-order');
            });
            module.exports.methods.updateAddToCartEnableDisableOtherElements(!enable);
        });
    },
    updateAvailability: function () {
        $('body').on('product:updateAvailability', function (e, response) {
            $('div.availability', response.$productContainer)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available && response.product.availablePrice);

            $('.availability-msg', response.$productContainer)
                .empty().html(response.message);

            if ($('.global-availability').length) {
                var allAvailable = $('.product-availability').toArray()
                    .every(function (item) { return $(item).data('available'); });

                var allReady = $('.product-availability').toArray()
                    .every(function (item) { return $(item).data('ready-to-order'); });

                $('.global-availability')
                    .data('ready-to-order', allReady)
                    .data('available', allAvailable);

                $('.global-availability .availability-msg').empty()
                    .html(allReady ? response.message : response.resources.info_selectforstock);
            }
        });
    },
    sizeChart: function () {
        $('.size-chart a').on('touchstart click', function (e) {
            e.preventDefault();
            var url = $(this).attr('href');
            var $prodSizeChart = $(this).closest('.size-chart').find('.size-chart-collapsible');
            if ($prodSizeChart.is(':empty')) {
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    success: function (data) {
                        $prodSizeChart.append(data.content);
                    },
                    error: function (err) {
                        console.error(err);
                    }
                });
            }
            $prodSizeChart.toggleClass('active');
        });

        var $sizeChart = $('.size-chart-collapsible');
        $('body').on('touchstart click', function (e) {
            if ($('.size-chart').has(e.target).length <= 0) {
                $sizeChart.removeClass('active');
            }
        });
    },
    copyProductLink: function () {
        $('body').on('touchstart click', '#fa-link', function () {
            event.preventDefault();
            var $temp = $('<input>');
            $('body').append($temp);
            $temp.val($('#shareUrl').val()).select();
            document.execCommand('copy');
            $temp.remove();
            $('.copy-link-message').attr('role', 'alert');
            $('.copy-link-message').removeClass('d-none');
            setTimeout(function () {
                $('.copy-link-message').addClass('d-none');
            }, 3000);
        });
    },
    showRecommendations: function () {
        $(document).ready(function () {
            var carruselType = ['recommendationType1', 'recommendationType2'];
            $.each(carruselType, function (index, value) {
                var carrusel = $('#' + value + ' div[id^="pdpCarouselRecomendations-"]');
                var items = $('#' + value + ' div[id^="pdpCarouselRecomendations-"]').length;
                if (items > 0) {
                    carrusel.slick(optionsType);
                }
            });
        });
    },
    openWhatsapp: function () {
        $('.icon-wh').on('click', function (e) {
            e.preventDefault();
            window.open(
                this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600'
            );
            return false;
        });
    },
    fixedAddCartButton: function () {
        $(window).on('scroll', function () {
            if ((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) < 769) {
                var footerHeight = $('footer')[0].clientHeight;
                var footerIsVisible = document.scrollingElement.scrollHeight - document.scrollingElement.scrollTop - footerHeight <= window.innerHeight;
                if (footerIsVisible) {
                    $('.prices-add-to-cart-actions').eq(0).css({ position: 'absolute', bottom: '-' + ($('.prices-add-to-cart-actions')[0].clientHeight + 20) + 'px', 'box-shadow': 'none' });
                    $('#maincontent').css('margin-bottom', ($('.prices-add-to-cart-actions')[0].clientHeight + 20) + 'px');
                } else {
                    $('.prices-add-to-cart-actions').eq(0).css({ position: '', bottom: '', 'box-shadow': '' });
                }
            } else {
                $('.prices-add-to-cart-actions').eq(0).css({ position: '', bottom: '', 'box-shadow': '' });
            }
        });
    },
    stickyRigthColumn: function () {
        $(window).scroll(function () {
            var rigthColumn = $('#sticky-product-details');
            if ($(window).scrollTop() >= 1800) {
                rigthColumn.removeClass('product-details-fixed-top');
                rigthColumn.addClass('product-details-fixed-bottom');
                $('.page').css('overflow-x', 'hidden');
            } else if ($(window).scrollTop() >= 30) {
                rigthColumn.addClass('product-details-fixed-top');
                $('.page').css('overflow-x', 'unset');
                rigthColumn.removeClass('product-details-fixed-bottom');
                if ($('.search').hasClass('active')) {
                    rigthColumn.addClass('search-add-top');
                } else {
                    rigthColumn.removeClass('search-add-top');
                }
            } else {
                rigthColumn.removeClass('product-details-fixed-top');
                $('.page').css('overflow-x', 'hidden');
                rigthColumn.removeClass('product-details-fixed-bottom');
            }
        });
    },
    focusChooseBonusProductModal: base.focusChooseBonusProductModal,
    sizeSelectorAddToCartModal: base.sizeSelectorAddToCartModal,
    pressEnterAndClickInElementRecommedations: handleADA.pressEnterToClickInsideElement('.pdpRecommendation', '.tile-body', 'button'),
    pressEnterAndClickInElementSizes: handleADA.pressEnterToClickInsideElement('.attribute > ul', 'li', 'li'),
    pressEnterAndClickInElementDescriptionAndDetail: handleADA.pressEnterToClickInsideElement('.description-and-detail', '.collapsible-xl', 'h2'),
    focusNextElementOutsidefromCollapsibleInfoWhenIsClosedAndPressTab: handleADA.focusNextElementOutsidefromContainerWhenPressTab('.description-and-detail > div:nth-child(3)', 'active', '.icon-fb'),
    collapsibleInfoItems: function () {
        $('.collapsible').on('click', function (event) {
            if ($(event.target).closest('.content').length) {
                return;
            }

            var current = $(this);
            if (current.hasClass('active')) {
                current.removeClass('active');
                current.attr('aria-expanded', false);
            } else {
                var currentId = current.parent().attr('id');
                $('.collapsible').each(function () {
                    var collapsible = $(this);
                    var id = collapsible.parent().attr('id');
                    if (currentId != id) {
                        if (collapsible.hasClass('active')) {
                            collapsible.removeClass('active');
                            collapsible.attr('aria-expanded', false);
                        }
                    } else {
                        collapsible.addClass('active');
                        collapsible.attr('aria-expanded', true);
                    }
                });
            }
        });
    },
    customSelectSize: base.customSelectSize,
    ekomiScroll: function () {
        $('.ekomi-widget-container').click(function (e) {
            e.preventDefault();
            var id = '#reviewsContainer';
            $('html,body').animate({ scrollTop: $(id).offset().top - 100 }, 1000);
        });
    },
    ekomiName: ekomiName,
    moreReviews: function () {
        setTimeout(function () {
            var names = document.querySelectorAll('.reviews-stack .review_date');
            if (names.length > 0) {
                for (var nameIndex = 0; nameIndex < names.length; nameIndex++) {
                    var name = names[nameIndex].firstChild && names[nameIndex].firstChild.nodeValue && names[nameIndex].firstChild.nodeValue.split(' ')[0];
                    if (names[nameIndex].firstChild && names[nameIndex].firstChild.nodeValue) {
                        names[nameIndex].firstChild.nodeValue = name;
                    }
                }
            }
            var button = document.querySelectorAll('.read-more-reviews');
            for (var i = 0; i < button.length; i++) {
                button[i].addEventListener('click', function () {
                    ekomiName();
                });
            }
        }, 3500);
    },
    createCarouselDesktop: function () {
        $(document).ready(function () {
            var carrusel = $('#zoom #image-container');
            var slides = $('#zoom #image-container img.zoom-slide');
            $.each(slides, function () {
                carrusel.append($(this));
            });
        });
    },
    closeZoomDesktop: function () {
        $('#zoom').click(function (event) {
            if (event.target.id == $('#zoom').attr('id')) {
                $('#zoom').addClass('d-none');
                var parentZoom = $('#zoom #image-container');
                parentZoom.trigger('zoom.destroy');
            }
        });

        $('#zoom .close').click(function () {
            $('#zoom').addClass('d-none');
            var parentZoom = $('#zoom #image-container');
            parentZoom.trigger('zoom.destroy');
        });

        $('#zoom .navigation').click(function (event) {
            if (!event.target.className.includes('left') && !event.target.className.includes('right')) {
                $('#zoom').addClass('d-none');
                var parentZoom = $('#zoom #image-container');
                parentZoom.trigger('zoom.destroy');
            }
        });
    },
    openZoomDesktop: function () {
        $('#galleryBox div[class^="mediaBox-"]').click(function () {
            var deviceScreenWidth = $(window).width();
            if (deviceScreenWidth >= mediaBreakLimit) {
                var parentZoom = $('#zoom #image-container');
                var view = $(this).children('a').children('img').attr('src').split('/');
                view = view[view.length - 1];
                view = view.split('-')[0];
                $('#zoom #image-container img').addClass('d-none').removeClass('current');
                var currentImg = $('#zoom #image-container img[src*="/' + view + '-"]');
                currentImg.removeClass('d-none');
                currentImg.addClass('current');
                $('#zoom').removeClass('d-none');
                parentZoom.zoom({
                    on: 'grab',
                    url: currentImg.attr('src'),
                    onZoomIn: function () {
                        $(this).css('cursor', 'zoom-out');
                    },
                    onZoomOut: function () {
                        $(this).css('cursor', 'zoom-in');
                    }
                });
            }
        });
    },
    navigateZoomDesktop: function () {
        function loopSlide(node, direction) {
            $.spinner().start();
            var parentZoom = $('#zoom #image-container');
            parentZoom.zoom().trigger('zoom.destroy');

            $('#zoom #image-container img.zoom-slide.current').removeClass('current');
            $('#zoom #image-container img.zoom-slide').addClass('d-none');
            if (node.length == 0) {
                var loop = $('#zoom #image-container img.zoom-slide');
                node = direction === 'right' ? loop.first() : loop.last();
            }
            node.removeClass('d-none').addClass('current');

            parentZoom.zoom({
                on: 'grab',
                url: node.attr('src'),
                onZoomIn: function () {
                    $(this).css('cursor', 'zoom-out');
                },
                onZoomOut: function () {
                    $(this).css('cursor', 'zoom-in');
                }
            });
            $.spinner().stop();
        }

        $('#zoom .navigation .right').click(function () {
            var next = $('#zoom #image-container img.zoom-slide.current').next('img.zoom-slide');
            loopSlide(next, 'right');
        });

        $('#zoom .navigation .left').click(function () {
            var prev = $('#zoom #image-container img.zoom-slide.current').prev('img.zoom-slide');
            loopSlide(prev, 'left');
        });
    },
    initTechnicalIconsSlider: function () {
        var container = document.querySelector('.technicalIconBlock');
        var slider = container.querySelector('ul');
        if (!slider) return;

        var isDown = false;
        var startX;
        var scrollLeft;

        /** Codigo que segun la resolucion marca si es mobile o no, y si es asi deja el justifycontent de manera fija, o si hay mas iconos de los esperados flex-start para realizar scroll */
        function updateLayout() {
            var items = slider.querySelectorAll('li');
            var itemCount = items.length;
            var isMobile = window.innerWidth <= 768;
            var limit = isMobile ? 4 : 7;

            if (itemCount <= limit) {
                slider.style.justifyContent = 'center';
                slider.style.width = '100%';
            } else {
                slider.style.justifyContent = 'flex-start';
                slider.style.width = 'max-content';
            }
        }

        function startDragging(e) {
            isDown = true;
            container.style.cursor = 'grabbing';
            startX = e.type.includes('mouse') ? e.pageX : e.touches[0].pageX;
            scrollLeft = container.scrollLeft;
        }

        function stopDragging() {
            isDown = false;
            container.style.cursor = 'grab';
        }

        function drag(e) {
            if (!isDown) return;
            e.preventDefault();
            var x = e.type.includes('mouse') ? e.pageX : e.touches[0].pageX;
            var walk = (x - startX) * 2;
            container.scrollLeft = scrollLeft - walk;
        }

        updateLayout();
        window.addEventListener('resize', updateLayout);

        container.addEventListener('mousedown', startDragging);
        container.addEventListener('touchstart', startDragging);

        document.addEventListener('mousemove', drag);
        document.addEventListener('touchmove', drag);

        document.addEventListener('mouseup', stopDragging);
        document.addEventListener('touchend', stopDragging);

        container.addEventListener('mouseleave', stopDragging);
    }
};
