window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');
var smoothscroll = require('smoothscroll-polyfill');

$(document).ready(function () {
    $('.logo-home').focus();
    processInclude(require('./components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('base/components/consentTracking'));
    processInclude(require('base/components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('base/components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('./components/countrySelectorModal'));
    processInclude(require('./components/geolocationModal'));
    processInclude(require('./components/newsletterModal'));
    processInclude(require('./login/login'));
    processInclude(require('./utilities/navigationUtilities'));
    smoothscroll.polyfill();
});

require('base/thirdParty/bootstrap');
require('./components/spinner');
require('slick-carousel');
require('./components/slick-lightbox/slick-lightbox');
