/* eslint-disable no-undef */

'use strict';


$(document).ready(function () {
    var formValidation = require('base/components/formValidation');
    var createErrorNotification = require('base/components/errorNotification');
    var inputAnimation = require('../common/input-animation');

    /* Aquí cargamos todo lo necesario para utilizar el plugin intlTelInput, y validar el teléfono móvil introducido por el usuario */

    var inputPhone = document.querySelector('#registration-form-phone, #registration-form-phone-checkout');
    var phone;
    var locale;
    if (inputPhone) {
        locale = window && window.CQuotient && window.CQuotient.locale;
        locale = locale.split('_')[1];
        phone = intlTelInput(inputPhone, {
            utilsScript           : 'https://www.pikolinos.com/on/demandware.static/-/Library-Sites-GrupoPikolinos/default/plugins/TelephoneInputValidator/js/utils.js',
            autoInsertDialCode    : true,
            nationalMode          : false,
            separateDialCode      : true,
            autoPlaceholder       : 'polite',
            placeholderNumberType : 'MOBILE',
            initialCountry        : locale,
            preferredCountries    : ['ES', 'US', 'FR', 'DE', 'BE', 'NL', 'GB', 'PT']
        });
    }

    function validatePhoneData() {
        var valid = phone.isValidNumber();
        var errorType = phone.getValidationError();
        var isPhoneEmpty = phone.getNumber() === '';
        var isMobilePhone = phone.getNumberType(); // Móvil == 1, Para USA no se distingue entre fijo y móvil

        if (!isPhoneEmpty && !valid && errorType === intlTelInputUtils.validationError.TOO_SHORT) {
            $('#form-phone-error').text($('#' + phone.a.id).attr('data-phone-short'));
            $('#form-phone-error').show();
            return valid;
        } else if (!isPhoneEmpty && !valid && errorType === intlTelInputUtils.validationError.TOO_LONG) {
            $('#form-phone-error').text($('#' + phone.a.id).attr('data-phone-long'));
            $('#form-phone-error').show();
            return valid;
        } else if (!isPhoneEmpty && isMobilePhone !== intlTelInputUtils.numberType.MOBILE && isMobilePhone !== intlTelInputUtils.numberType.FIXED_LINE_OR_MOBILE && errorType !== 0) {
            $('#form-phone-error').text($('#' + phone.a.id).attr('data-phone-mobile'));
            $('#form-phone-error').show();
            return valid;
        } else if (!isPhoneEmpty && !valid && errorType !== 0) {
            $('#form-phone-error').text($('#' + phone.a.id).attr('data-phone-error'));
            $('#form-phone-error').show();
            return valid;
        } else if (isPhoneEmpty || errorType === -99) {
            $('#form-phone-error').text($('#' + phone.a.id).attr('data-pattern-mismatch'));
            $('#form-phone-error').show();
            return valid;
        } else if (valid) {
            $('#form-phone-error').hide();
            return valid;
        }
        return true;
    }

    function getPhoneNumber() {
        return phone && phone.getNumber().trim();
    }

    function moveAlert() {
        if ($('.alert.alert-danger:not(".newsletter-alert-message")').length > 1) {
            $('.alert.alert-danger:not(".newsletter-alert-message")')[0].remove();
        }
        var container = $('.login-page')[0];
        var divToMove = $('.alert.alert-danger:not(".newsletter-alert-message")')[0];
        if (divToMove.childNodes.length > 0) {
            var title = '<h5>' + divToMove.childNodes[0].data + '</h5>';
            var desc = '<p>' + divToMove.childNodes[2].data + '</p>';
            divToMove.innerHTML = title + desc;
        }
        container.insertBefore(divToMove, container.firstChild);
    }

    function isSameEmails(form) {
        // form validation
        var emailValue = form.find('#registration-form-email-login, #registration-form-email')[0].value;
        var emailConfirmValue = form.find('#registration-form-email-confirm, #registration-form-email-confirm')[0].value;

        return emailValue.toLowerCase() === emailConfirmValue.toLowerCase();
    }

    function hidePreviousConfirmationMessage() {
        if (!$('.recover-password-notification').hasClass('d-none')) {
            $('.recover-password-notification').addClass('d-none');
            $('.recover-password-notification').removeClass('d-block');
        }
        if (!$('.confirm-reset-password-notification').hasClass('d-none')) {
            $('.confirm-reset-password-notification').addClass('d-none');
            $('.confirm-reset-password-notification').removeClass('d-block');
        }
    }

    /**
    * Funciones para ocultar o mostrar la contraseña en los diferentes formularios.
    */
    function showPasswordIconsCurrent(input) {
        input.type = 'text';
        $('#hidePasswordCurrent').removeClass('d-none');
        $('#showPasswordCurrent').addClass('d-none');
    }

    function showPasswordIcons(input) {
        input.type = 'text';
        $('#hidePassword').removeClass('d-none');
        $('#showPassword').addClass('d-none');
    }

    function showPasswordIconsLogin(input) {
        input.type = 'text';
        if (input.id.includes('-normal')) {
            $('#hidePasswordLogin-normal').removeClass('d-none');
            $('#showPasswordLogin-normal').addClass('d-none');
        } else if (input.id.includes('-mini')) {
            $('#hidePasswordLogin-mini').removeClass('d-none');
            $('#showPasswordLogin-mini').addClass('d-none');
        }
    }

    function showPasswordIconsConfirm(input) {
        input.type = 'text';
        $('#hidePasswordConfirm').removeClass('d-none');
        $('#showPasswordConfirm').addClass('d-none');
    }

    function hidePasswordIconsCurrent(input) {
        input.type = 'password';
        $('#hidePasswordCurrent').addClass('d-none');
        $('#showPasswordCurrent').removeClass('d-none');
    }

    function hidePasswordIcons(input) {
        input.type = 'password';
        $('#hidePassword').addClass('d-none');
        $('#showPassword').removeClass('d-none');
    }

    function hidePasswordIconsLogin(input) {
        input.type = 'password';
        if (input.id.includes('-normal')) {
            $('#hidePasswordLogin-normal').addClass('d-none');
            $('#showPasswordLogin-normal').removeClass('d-none');
        } else if (input.id.includes('-mini')) {
            $('#hidePasswordLogin-mini').addClass('d-none');
            $('#showPasswordLogin-mini').removeClass('d-none');
        }
    }

    function hidePasswordIconsConfirm(input) {
        input.type = 'password';
        $('#hidePasswordConfirm').addClass('d-none');
        $('#showPasswordConfirm').removeClass('d-none');
    }

    function checkConstraints(inputText) {
        if (inputText.length >= 8) {
            return true;
        }
        return false;
    }


    // PIK200620-32-MyAccount 2021/01/11 Recover email from sessionStorage when user is redirected from resetPasswordPage
    var resetPasswordEmailSession = sessionStorage.getItem('resetPasswordEmail');
    if (resetPasswordEmailSession) {
        $('.recover-password-email').append(sessionStorage.getItem('resetPasswordEmail'));
        $('.recover-password-notification').removeClass('d-none');
        $('.recover-password-notification').addClass('d-block');
        sessionStorage.removeItem('resetPasswordEmail');
    } else {
        var urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('confirmResetPassword') === 'true') {
            $('.confirm-reset-password-notification').removeClass('d-none');
            $('.confirm-reset-password-notification').addClass('d-block');
        }
    }

    $('form.login').submit(function (e) {
        var form = $(this);
        e.preventDefault();
        var url = form.attr('action');
        form.spinner().start();
        $('form.login').trigger('login:submit', e);
        hidePreviousConfirmationMessage();
        $.ajax({
            url      : url,
            type     : 'post',
            dataType : 'json',
            data     : form.serialize(),
            success  : function (data) {
                form.spinner().stop();
                if (!data.success) {
                    formValidation(form, data);
                    moveAlert();
                    $('form.login').trigger('login:error', data);
                    scroll({ behavior: 'smooth', top: $('.alert-danger')[0].offsetTop - ($('.header-navigation-content').height() + 100) });
                } else {
                    $('form.login').trigger('login:success', data);
                    location.href = data.redirectUrl;
                }
            },
            error: function (data) {
                if (data.responseJSON.redirectUrl) {
                    window.location.href = data.responseJSON.redirectUrl;
                } else {
                    $('form.login').trigger('login:error', data);
                    form.spinner().stop();
                    scroll({ behavior: 'smooth', top: $('.alert-danger')[0].offsetTop });
                }
            }
        });
        return false;
    });

    /* Validación de los datos, email, teléfono*/
    $('form.registration').on('touchstart click', '#submit', function () {
        var form = $('form.registration');
        var emailValid = form.find('#registration-form-email-login, #registration-form-email')[0].checkValidity();
        var emailConfirmValid = form.find('#registration-form-email-confirm, #registration-form-email-confirm')[0].checkValidity();
        if (!$('#form-email-confirm-error-not-same').hasClass('d-none')) {
            $('#form-email-confirm-error-not-same').addClass('d-none');
        }
        if (!$('#password-reset-register').hasClass('d-none')) {
            $('#password-reset-register').addClass('d-none');
        }
        if (emailValid && emailConfirmValid) {
            if (!isSameEmails(form)) {
                $('#form-email-confirm-error-not-same').removeClass('d-none');
            }
        }

        // Validamos el telefono, para en caso de tener un error externo al formulario, no realizar la llamda. El form comprueba que no esté vacio y longitud, pero ahora con el plugin comprobamos que el teléfono sea válido para el país que ha seleccionado el cliente.
        validatePhoneData();
    });

    $('form.registration').submit(function (e) {
        if (validatePhoneData()) {
            $('#form-phone-error').hide();
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            var isNewsletterChecked = $('#add-to-email-list').is(':checked');
            // when this event is executed, the form is correct but it has not been verified if the emails are the same
            $('#registration-form-phone').val(getPhoneNumber());

            if (!$('#form-email-confirm-error-not-same').hasClass('d-none')) {
                $('#form-email-confirm-error-not-same').addClass('d-none');
            }
            if (!$('#password-reset-register').hasClass('d-none')) {
                $('#password-reset-register').addClass('d-none');
            }
            $('form.registration').trigger('login:register', e);
            hidePreviousConfirmationMessage();
            $.ajax({
                url      : url,
                type     : 'post',
                dataType : 'json',
                data     : form.serialize(),
                success  : function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        if (data.existCustomer) {
                            $('#password-reset-register').removeClass('d-none');
                        }
                        $('form.registration').trigger('login:register:error', data);
                        formValidation(form, data);
                    } else {
                        if (isNewsletterChecked) {
                            var pageType = '';
                            /* eslint-disable no-undef */
                            // Recorremos el datalayer para obtener el pageType
                            dataLayer.forEach(function (evento) {
                                if (evento.event === 'pagina') pageType = evento.pageType;
                            });

                            var etiqueta = pageType + '-' + window.location.href;
                            dataLayer.push({
                                event     : 'Suscripcion Newsletter',
                                Categoría : 'Suscripcion Newsletter',
                                Acción    : 'Suscripcion Enviada',
                                Etiqueta  : etiqueta
                            });
                        }

                        $('form.registration').trigger('login:register:success', data);
                        location.href = data.redirectUrl;
                    }
                },
                error: function (err) {
                    form.spinner().stop();
                    if (!err.responseJSON) {
                        createErrorNotification($('.error-messaging'), 'There was an error in the google captcha validation, please try again in a few minutes');
                        scroll({ behavior: 'smooth', top: $('.error-messaging')[0].offsetTop });
                    } else if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                        scroll({ behavior: 'smooth', top: $('.error-messaging')[0].offsetTop });
                    }
                }
            });
        }
        return false;
    });

    $('.reset-password-form').submit(function (e) {
        var form = $(this);
        e.preventDefault();
        var url = form.attr('action');
        form.spinner().start();
        $('.reset-password-form').trigger('login:register', e);
        $.ajax({
            url      : url,
            type     : 'post',
            dataType : 'json',
            data     : form.serialize(),
            success  : function (data) {
                form.spinner().stop();
                if (!data.success) {
                    formValidation(form, data);
                } else {
                    var a_href = $('#backToLogin').attr('href');
                    // PIK200620-32-MyAccount 2021/01/11 Recover email from sessionStorage when user is redirected from resetPasswordPage
                    sessionStorage.removeItem('resetPasswordEmail');
                    sessionStorage.setItem('resetPasswordEmail', form[0][0].value);
                    window.location.href = a_href;
                }
            },
            error: function (err) {
                console.error(err);
                form.spinner().stop();
            }
        });
        return false;
    });

    $('#login .modal').on('hidden.bs.modal', function () {
        $('#reset-password-email').val('');
        $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
    });


    $('.tabs').on('touchstart click', '.tabs-btn', function (e) {
        e.preventDefault();
        var id = $(this).attr('rel');
        $('.tabs-btn').removeClass('active');
        $(this).addClass('active');
        $('.tab-pane').hide();
        $('#' + id).show();
    });

    $('#showPasswordCurrent, #hidePasswordCurrent').on('touchstart click', function () {
        var input = $(this).siblings('input')[0];
        if (input.type === 'password') {
            showPasswordIconsCurrent(input);
        } else {
            hidePasswordIconsCurrent(input);
        }
    });

    $('#showPassword, #hidePassword').on('touchstart click', function () {
        var input = $(this).siblings('input')[0];
        if (input.type === 'password') {
            showPasswordIcons(input);
        } else {
            hidePasswordIcons(input);
        }
    });

    $('#showPasswordLogin-normal, #showPasswordLogin-mini, #hidePasswordLogin-normal, #hidePasswordLogin-mini').on('touchstart click', function () {
        var input = $(this).siblings('input')[0];
        if (input.type === 'password') {
            showPasswordIconsLogin(input);
        } else {
            hidePasswordIconsLogin(input);
        }
    });

    $('#showPasswordConfirm, #hidePasswordConfirm').on('touchstart click', function () {
        var input = $(this).siblings('input')[0];
        if (input.type === 'password') {
            showPasswordIconsConfirm(input);
        } else {
            hidePasswordIconsConfirm(input);
        }
    });

    $('#registration-form-password, #newPassword').on('keyup', function () {
        var inputText = $('#registration-form-password, #newPassword').val();
        if (checkConstraints(inputText)) {
            $('#checkPassword').removeClass('d-none');
        } else {
            $('#checkPassword').addClass('d-none');
        }
    });

    $('#registration-form-password-confirm, #newPasswordConfirm').on('keyup', function () {
        var pass = $('#registration-form-password, #newPassword').val();
        var passConfirm = $('#registration-form-password-confirm, #newPasswordConfirm').val();
        if (checkConstraints(passConfirm) && pass === passConfirm) {
            $('#checkPasswordConfirm').removeClass('d-none');
        } else {
            $('#checkPasswordConfirm').addClass('d-none');
        }
    });

    /** Adding no-empty class to input in order to animate the label */
    inputAnimation.labelAnimation();
});

