'use strict';

function pressEnterItem(actionItem, itemForClick) {
    $(actionItem).keypress(function (e) {
        if (e.which === 13) {
            if (itemForClick) {
                $(itemForClick).click();
            } else {
                $(actionItem).click();
            }
        }
    });
}

function pressEnterToClickInsideElement(container, itemForEnter, itemForNavigate) {
    $(container).on('keydown', itemForEnter, function (e) {
        if (e.which === 13) {
            if (itemForEnter === itemForNavigate) {
                $(e.target).click();
            } else if ($(e.target).find(itemForNavigate)[0]) {
                $(e.target).find(itemForNavigate)[0].click();
            } else {
                $(e.target).find(itemForNavigate).click();
            }
        }
    });
}

function pressEnterToNavigateUsingAInsideElement(container, itemForEnter, itemForNavigate) {
    $(container).on('keydown', itemForEnter, function (e) {
        if (e.which === 13) {
            $(location).attr('href', $(e.target).find(itemForNavigate)[0].href);
        }
    });
}

function focusNextElementOutsidefromSortOrderContainerWhenPressTab() {
    $('#sort-order-by-button').keydown(function (e) {
        if (e.which === 9 && !$('#sort-by-container').hasClass('show')) {
            $('.refinement-tipo > .card-header > button').focus();
        }
    });
}

function focusNextElementOutsidefromContainerWhenPressTab(container, flagClass, nextElement) {
    $(container).keydown(function (e) {
        if (e.which === 9 && !$(container).hasClass(flagClass)) {
            e.preventDefault();
            $(nextElement).focus();
        }
    });
}

function pressEnterInHeaderActionsItems(actionItem) {
    $('.header-action-item').has(actionItem).keypress(function (e) {
        if (e.which === 13) {
            $(actionItem).click();
        }
    });
}

function tabParagraphCollapsible(currentElement, nextElement) {
    $(currentElement).on('blur', function (event) {
        if (event.which === 0 && $(this).attr('aria-expanded') === 'false') {
            $(nextElement).trigger('focus');
        }
    });
}

function shippingMethod() {
    $('[id^="labelshippingMethod"]').keypress(function (e) {
        var id = $(this).prop('id');
        id = id.slice(5);
        if (e.which === 13) {
            $('#' + id).trigger('click');
        }
    });
}

module.exports = {
    pressEnterItem                                            : pressEnterItem,
    focusNextElementOutsidefromSortOrderContainerWhenPressTab : focusNextElementOutsidefromSortOrderContainerWhenPressTab,
    pressEnterInHeaderActionsItems                            : pressEnterInHeaderActionsItems,
    tabParagraphCollapsible                                   : tabParagraphCollapsible,
    shippingMethod                                            : shippingMethod,
    pressEnterToNavigateUsingAInsideElement                   : pressEnterToNavigateUsingAInsideElement,
    pressEnterToClickInsideElement                            : pressEnterToClickInsideElement,
    focusNextElementOutsidefromContainerWhenPressTab          : focusNextElementOutsidefromContainerWhenPressTab
};
