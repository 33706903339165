'use strict';

module.exports = function () {
    function selectCountryAndLanguage(localesData) {
        var selectedCountry = $('select[name=countries-select]').val();
        $('.option-language').remove();
        var filteredLocales = localesData.filter(function (loc) {
            return loc.country === selectedCountry;
        });
        /* Add - PIK-202006-78 - 20201020 - Append a option html element for every local allowed in each country */
        filteredLocales.forEach(function (locale) {
            $('#country-selector-box-locales').append(
                '<option data-currency="' + locale.currencyCode + '" class="option-language" value="' + locale.localID + '">' + locale.displayLanguage + '</option>'
            );
        });
    }

    /* Add - PIK-202006-113 - 20201209 - Method to redirect to external web sites */
    function redirectToExternalLocaleSite(locale, extUrls) {
        if (extUrls) {
            switch (locale) {
            case 'es_MX':
                window.location.href = extUrls.urlMX;
                break;
            case 'en_CA':
                window.location.href = extUrls.urlCA;
                break;
            case 'cn_CN':
                window.location.href = extUrls.urlCN;
                break;
            case 'jp_JP':
                window.location.href = extUrls.urlJP;
                break;
            default:
            }
        }
    }

    /* Add - PIK-202006-78 - 20201020 - Method to request countries and locales by ajax */
    function getCountriesAjaxResponse() {
        var url = $('.country-selector').data('url');
        $.ajax({
            url      : url,
            method   : 'GET',
            dataType : 'json',
            success  : function (data) {
                var localesData = data.localesData;
                var siteLocale = data.siteLocale;
                /* Add - PIK-202006-78 - 20201020 - Append a option html element for every country allowed */
                data.allCountryCodes.forEach(function (country, index) {
                    $('#country-selector-box-countries').append(
                        '<option class="option-countries "value="' + data.allCountryCodes[index] + '">' + data.allCountryNames[index] + '</option>'
                    );
                });

                /* Add - PIK-202006-78 - 20201020 - Select the country of the site */
                $('select option[value="' + siteLocale.countryCode + '"]').attr('selected', true);

                /* Add - PIK-202006-78 - 20201020 - Filter the locales from the country selected */
                selectCountryAndLanguage(localesData);

                /* Add - PIK-202006-78 - 20201020 - When Country changes the previous locales are removed
                    and the specifics ones from the choosen country are reappended */
                $('select[name=countries-select]').change(function () {
                    selectCountryAndLanguage(localesData);
                });
            },
            error: function (e) {
                console.error(e);
            }
        });
    }

    var countrySelectorModalClass = '.countrySelectorModalClass';
    /* Add> - PIK-202006-78 - 20201020 - Display modal when user clicks the country-selector and doing the ajax request */
    $('body').on('click', '.country-selector', function (e) {
        e.preventDefault();
        $('.option-countries').remove();
        $('.option-language').remove();
        getCountriesAjaxResponse();
        $(countrySelectorModalClass).css('display', 'block');
    });

    $('body').on('click', '.geolocationModalLinkCountrySelector', function (e) {
        e.preventDefault();
        $('.geolocationModal').css('display', 'none');
        $('.option-countries').remove();
        $('.option-language').remove();
        getCountriesAjaxResponse();
        $(countrySelectorModalClass).css('display', 'block');
    });

    /* Add - PIK-202006-78 - 20201020 - Removes from DOM the options from country-selector and locales-selector when user close the modal */
    $('body').on('click', '.country-selector-modal-close-button', function (e) {
        e.preventDefault();
        $('.option-countries').remove();
        $('.option-language').remove();
        $(countrySelectorModalClass).css('display', 'none');
    });

    /* Add - PIK-202006-78 - 20201020 - When user clicks the submit button redirect to choosen locale whit ajax request */
    $('body').on('click', '.country-selector-modal-submit-button', function (e) {
        e.preventDefault();
        var localeSelected = $('select[name=locales-select]').val();
        var currencySelected = $('.option-language:first').data('currency');
        var url = $('.country-selector-modal-submit-button').data('url');
        var queryString = $('.page').data('querystring');
        var action = $('.page').data('action');

        $.ajax({
            url      : url,
            type     : 'get',
            dataType : 'json',
            data     : {
                code         : localeSelected,
                queryString  : queryString,
                CurrencyCode : currencySelected,
                action       : action
            },
            success: function (response) {
                if (response) {
                    if (response.redirectUrl) {
                        window.location.href = response.redirectUrl;
                    } else if (response.externalUrls) {
                        /* Add - PIK-202006-113 - 20201201 - Add redirect for the external sites */
                        redirectToExternalLocaleSite(localeSelected, response.externalUrls);
                    } else {
                        console.debug('Response from redirect in country selector is not received');
                    }
                }
            },
            error: function (err) {
                console.error('error... action', action, err);
            }
        });

        $('.option-countries').remove();
        $('.option-language').remove();
        $(countrySelectorModalClass).css('display', 'none');
    });
};
