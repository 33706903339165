'use strict';

require('detect-autofill');

/** Adding no-empty class to input in order to animate the label */
function labelInputAnimation() {
    $.each($('.form-control'), function () {
        if (!$(this).val()) {
            ($(this).prev('.form-group .form-control-label').hasClass('no-empty')) ? $(this).prev('.form-group .form-control-label').removeClass('no-empty') : '';
        } else {
            (!$(this).prev('.form-group .form-control-label').hasClass('no-empty')) ? $(this).prev('.form-group .form-control-label').addClass('no-empty') : '';
        }
    });
}

module.exports = {
    /** Adding no-empty class to input in order to animate the label */
    labelAnimation: function () {
        labelInputAnimation();
        $('.form-control').blur(function () {
            if (!$(this).val()) {
                $(this).prev('.form-group .form-control-label').removeClass('no-empty');
            }
        })
            .focus(function () {
                $(this).prev('.form-group .form-control-label').addClass('no-empty');
            });
        document.addEventListener('onautocomplete', function (e) {
            var children = e.target.parentNode.childNodes;
            for (var i = 0; i < children.length; i++) {
                if ($(children[i]).hasClass('form-control-label')) {
                    $(children[i]).addClass('no-empty');
                    break;
                }
            }
        });
    }
};
