function blockNewTabInButtonQuickView() {
    $(document).on('contextmenu', 'a.quickview', function () {
        return false;
    });

    $(document).on('auxclick', 'a.quickview', function (e) {
        if (e.which === 2) { // middle Click
            return false;
        }
        return true;
    });
}

module.exports = {
    blockNewTabInButtonQuickView: blockNewTabInButtonQuickView
};
